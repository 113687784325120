<script>
import axios from "axios";

import Layout from "../../layouts/auth";
import { authMethods } from "@/state/helpers";
import appConfig from "@/app.config";

import { required, email, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

/**
 * Forgot Password component
 */
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  validations: {
    email: {
      required: helpers.withMessage("Email is required", required),
      email: helpers.withMessage("Please enter valid email", email),
    },
  },
  page: {
    title: "Forgot Password",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
  },
  data() {
    return {
      app_URL: process.env.VUE_APP_URL,
      email: "",
      submitted: false,
      errors: [],
      tryingToReset: false,
      alertErrors: false,
      success: "",
      isSuccess: false,
      isErrorMessage: false,
      errorMessage: "",
    };
  },
  methods: {
    ...authMethods,
    // Try to register the user in with the email, fullname
    // and password they provided.
    tryToReset() {
      this.submitted = true;
      // stop here if form is invalid
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        axios.post(this.app_URL + "api/forgotPassword", { email: this.email }).then((res) => {
          console.log(res);
          this.isSuccess = true;
          this.success = res.data.message;
          return res;
        }).catch(error => {
          if (error.response.status === 403) {
            this.isErrorMessage = true;
            this.errorMessage = 'You do not have permission to reset your password.';
            return;
          }
          this.errors = error.response.data.errors;
          this.alertErrors = true;
        });
      }
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft">
            <div class="row">
              <div class="col-12 text-center py-3">
                <img src="/img/whinchester.4ab63a21.png" alt="whinchester Logo" height="80">
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <!-- <div>
              <router-link to="/">
              </router-link>
            </div> -->

            <div class="p-2">
              <form>
                <div class="mb-3">
                  <h2 style="font-size:20px;margin-bottom:10px;margin-top:5px;"> Reset Your Passward</h2>
                  <p style="font-size:14px;margin-bottom:15px;">Enter your email address and we will send you a link to
                    reset your password.</p>
                  <b-alert v-model="isErrorMessage" class="mb-4" variant="danger" dismissible>{{ errorMessage }}</b-alert>
                  <b-alert v-model="alertErrors" class="mb-4" variant="danger" dismissible>{{ errors.email[0] }}</b-alert>
                  <b-alert v-model="isSuccess" class="mb-4" variant="success" dismissible>{{ success }}</b-alert>
                  <label for="useremail">Email</label>
                  <input type="email" v-model="email" class="form-control" id="useremail" placeholder="Enter email"
                    :class="{ 'is-invalid': submitted && v$.email.$error }" />
                  <div v-for="(item, index) in v$.email.$errors" :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                  </div>
                </div>
                <div class="mb-3 row mb-0">
                  <div class="col-12 text-end">
                    <button class="btn btn-theme w-md" type="button" @click.prevent="tryToReset">
                      Reset
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="mt-5 text-center">
          <!-- <p>
            Remember It ?
            <router-link to="/login" class="fw-medium text-primary"
              >Sign In here</router-link
            >
          </p> -->
          <p>
            © {{ new Date().getFullYear() }}Whinchester. Crafted with
            <i class="mdi mdi-heart text-danger"></i> by Midriff
          </p>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style lang="scss" module></style>
